import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"

import SEO from "../components/SEO"
import Paragraph from "../components/Paragraph"
import { injectIntl } from "react-intl"

const ContactPage = ({ intl }) => {
  const rawData = useStaticQuery(graphql`
    {
      nl: markdownRemark(
        frontmatter: { id: { eq: "contact" }, langKey: { eq: "nl" } }
      ) {
        html
        ...SEO
        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 604, maxHeight: 593) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image_alt
          info_text
        }
      }
      fr: markdownRemark(
        frontmatter: { id: { eq: "contact" }, langKey: { eq: "fr" } }
      ) {
        html
        ...SEO
        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 604, maxHeight: 593) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image_alt
          info_text
        }
      }
    }
  `)

  const node = rawData[intl.locale]

  const {
    frontmatter: { title, ogImage },
  } = node

  return (
    <>
      <SEO title={title} ogImage={ogImage} />

      <Layout>
        <div className="c-page-wrapper c-page-wrapper--flush">
          <div className="c-container">
            <Paragraph equalHeight node={node} />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default injectIntl(ContactPage)
